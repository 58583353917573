import Router, { useRouter } from 'next/navigation';
import { withPageAuthRequired } from '@auth0/nextjs-auth0/client';
import NWLSpinner from 'components/NWLSpinner';
import { pageView } from 'lib/analytics';
import Header from 'components/Header/Header';
import Footer from 'components/Footer';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { clearModal } from 'lib/redux/actions';
import { MODAL_ACTIONS_FN } from 'lib/utils/constants';
import fetch from 'isomorphic-unfetch';
import logger from 'lib/utils/logger';

const isDev = process.env.NODE_ENV !== 'production';

// Router.onRouteChangeComplete = (url) => {
//   pageView({
//     url: `https://${window.location.hostname.replace(/^\//, '')}${url}`,
//     ANALYTICS_TOKEN: process.env.ANALYTICS_TOKEN,
//     isDev,
//   });
// };

function Layout(props) {
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const closeModal = useCallback(() => {
    if (modal.onClose) {
      MODAL_ACTIONS_FN[modal.onClose]();
    }
    dispatch(clearModal({ content: null, isOpen: false }));
  });
  const router = useRouter();

  window.onfocus = async () => {
    try {
      const response = await fetch(`${window.location.origin}/api/auth/me`, {
        method: 'GET',
      });
      if (response.status !== 200) {
        throw new Error('Not authenticated');
      }
    } catch (error) {
      logger.error(error, 'User session expired');
      router.refresh();
    }
  };

  return (
    <div className="main">
      {modal && (
        <Modal isOpen={modal.isOpen}>
          {modal.title && <ModalHeader>{modal.title}</ModalHeader>}
          <ModalBody>{modal.content}</ModalBody>
          <ModalFooter>
            <Button onClick={closeModal}>Close</Button>
          </ModalFooter>
        </Modal>
      )}
      {props.showNavBar && <Header auth={props.auth} />}
      {props.children}
      <Footer className="footer" auth={props.auth} />

      <style jsx global>
        {`
          .main {
            display: flex;
            min-height: 100vh;
            flex-direction: column;
            flex: 1;
            align-items: center;
          }
          .flex {
            display: flex;
          }
          .justify-center {
            justify-content: center;
          }
          .bullet-text {
            margin-left: 2%;
            margin-top: 2%;
            margin-bottom: 3%;
          }
          #main-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-width: 60em;
            flex: 1;
            padding: 1em;
          }
          nav {
            width: 100%;
          }
          footer {
            width: 100%;
          }
          .react-player-wrapper {
            width: 100%;
            margin: 1rem 0 1rem 0;
          }
          input {
            background: white;
          }
          input:-webkit-autofill {
            box-shadow: inset 0 0 0px 9999px white;
            -webkit-box-shadow: inset 0 0 0px 9999px white;
          }
          p {
            text-align: left;
          }
          h1,
          h2,
          h3 {
            text-align: center;
          }

          @media only screen and (max-width: 768px) {
            .bullet-point > svg {
              width: 40% !important;
            }
          }
        `}
      </style>
    </div>
  );
}

export default withPageAuthRequired(Layout, {
  onRedirecting: () => <NWLSpinner fillView actionText="loading" />,
  onError: (error) => <div>{error.message}</div>,
});
